import React from "react";
import {Text as DefaultText, StyleSheet} from 'react-native';

export type TextProps = DefaultText['props'];

const Heading: React.VFC<TextProps> = ({ style, ...otherProps }) => {
    return (
        <DefaultText style={[styles.text, style]} {...otherProps} />
    );
};

const styles = StyleSheet.create({
    text: {
        fontFamily: "Urbanist_Black",
        fontSize: 28,
        color: "#ffffff",
        textAlign: "center",
        lineHeight: 46,
        marginBottom: 10,
        letterSpacing: 1
    }
});

export default Heading;