import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { RootStackParamList } from '../types';

const linking: LinkingOptions<RootStackParamList> = {
    prefixes: [Linking.makeUrl('/')],
    config: {
        screens: {
            Home: "home",
            Title: "title",
            Story: "story",
            Conclusion: "conclusion",
            About: "about",
            Resources: "resources",
            TermsConditions: "terms"
        },
    },
};

export default linking;
