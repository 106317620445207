import React from "react";
import Svg, {Rect, Text} from "react-native-svg";
import {processFontFamily} from "expo-font";

type TitleTextProps = {
    text: string;
    fill: string;
    width: number;
    fontSize?: number;
}

/**
 * Text on title page.
 */
const TitleText: React.VFC<TitleTextProps> = ({ text, fill, width, fontSize = 38 }) =>
    <Svg width={width} height={50}>
        <Text
            x={width / 2 + 2} y={40 + 2}
            textAnchor="middle"
            stroke="#000000" strokeWidth={1.5}
            fill="#000000"
            fontSize={fontSize}
            letterSpacing={"1.5%"}
            fontFamily={processFontFamily("Urbanist_ExtraBold") || undefined}
        >{text}</Text>
        <Text
            x={width / 2} y={40}
            textAnchor="middle"
            stroke="#000" strokeWidth={1}
            fill={fill}
            fontSize={fontSize}
            letterSpacing={"1.5%"}
            strokeLinejoin={"round"}
            fontFamily={processFontFamily("Urbanist_ExtraBold") || undefined}
        >{text}</Text>
    </Svg>

export default TitleText;