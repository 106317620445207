import React, {useState} from "react";
import {Combo as ComboType} from "../types";
import Question from "./Question";
import Choice from "./Choice";

type ComboProps = {
    combo: ComboType;
    color: string;
    onChoice: (choice: number) => void;
    onReplay: () => void;
};

const Combo: React.VFC<ComboProps> = ({ combo, color, onChoice, onReplay }) => {
    const { questions, choice } = combo;

    const [ q, setQ ] = useState(0);

    const _nextQuestion = () => {
        setQ(q + 1);
        if (q + 1 === questions.length && combo.video)
            onChoice(0);
    };

    return (
        <React.Fragment>
            {q < questions.length && <Question question={questions[q]} color={color}
                                               onNext={_nextQuestion} onClose={onReplay}/>}
            {q === questions.length && choice && <Choice choice={choice} color={color}
                                               onChoice={onChoice} onClose={onReplay} />}

        </React.Fragment>
    );
};

export default Combo;