import React, {useEffect, useImperativeHandle, useRef, useState} from "react";
import {StyleSheet, useWindowDimensions, View} from "react-native";
import {AVPlaybackStatus, Video} from "expo-av";
import Spinner from "./common/Spinner";

export type VideoRef = {
    play: () => void;
    pause: () => void;
    stop: () => void;
    replay: () => void;
};

type VideoProps = {
    uri: string;
    placeholder: string;
    overlay?: React.ReactElement;
    onFinish?: () => void;
};

const VIDEO_WIDTH = 1080;
const VIDEO_HEIGHT = 1920;

const VideoPlayer = React.forwardRef<VideoRef, VideoProps>(({ uri, overlay, placeholder, onFinish  }, ref) => {
    const videoRef = useRef<any>(null);
    const [status, setStatus] = React.useState<AVPlaybackStatus>({ isLoaded: false });

    const [ stamp, setStamp ] = useState(Date.now());
    useEffect(() => setStamp(Date.now()), [uri]);

    const [ needOverlay, setNeedOverlay ] = useState(!!overlay);

    const _status =async (status: AVPlaybackStatus) => {
        setStatus(status);

        const stamp = Date.now();
        setTimeout(() => setStamp(stamp), 2000);

        if (status.isLoaded && status.didJustFinish && onFinish)
            onFinish();
    };

    useImperativeHandle(ref, () => ({
        play: () => {
            if (status.isLoaded && !status.isPlaying)
                videoRef.current?.playAsync();
        },
        pause: () => {
            if (status.isLoaded && status.isPlaying)
                videoRef.current?.pauseAsync();
        },
        stop: () => {
            if (status.isLoaded)
                videoRef.current?.stopAsync();
        },
        replay: async () => {
            const video = videoRef.current;
            if (!status.isLoaded || !video) return;

            if (status.isPlaying)
                await video.stopAsync();

            setStamp(Date.now());

            await video.setPositionAsync(0);
            await video.playAsync();
        }
    }));

    useEffect(() => {
        setNeedOverlay(!!overlay);

        try {
            if (videoRef.current) {
                console.log("Loading: ", uri);
                videoRef.current.loadAsync({uri}, { shouldPlay: false }, false)
                    .then(() => {
                        if (!overlay) {
                            videoRef.current?.playAsync();
                        }
                    })
                    .catch((err: any) => {
                        console.error("Failed loading video:", err);
                    });
            }
        }
        catch (e) {
            console.error("Failed loading video: ", e);
        }

        return () => {
            try {
                if (videoRef.current) {
                    console.log("Unloading: ", uri);
                    videoRef.current.unloadAsync();
                }
            }
            catch (e) {
                console.error("Failed unloading video: ", e);
            }
        };
    }, [ uri, videoRef.current ]);

    const _overlayClosed = () => {
        setNeedOverlay(false);
        videoRef.current?.playAsync();
    };

    const loading = !status.isLoaded || (status.isBuffering && !status.isPlaying);

    const { width } = useWindowDimensions();

    return (
        <>
            <View style={[ styles.container, {
                width,
                height: VIDEO_HEIGHT * width / VIDEO_WIDTH,
            } ]}>
                <Video
                    ref={videoRef}
                    style={styles.video}
                    source={{ uri }}
                    posterSource={{ uri: placeholder }}
                    usePoster
                    resizeMode="contain"
                    onPlaybackStatusUpdate={_status}
                />
                <View style={styles.spinner}>
                    {loading && Date.now() - stamp > 1000 && <Spinner />}
                </View>
            </View>
            {overlay && needOverlay && React.cloneElement(overlay, { onClose: _overlayClosed })}
        </>
    )
});

const styles = StyleSheet.create({
    container: {
        position: "relative",
        marginTop: 20,
        width: "100%",
    },
    video: {
        width: "100%",
        height: "100%",
        justifyContent: "center"
    },
    spinner: {
        position: "absolute",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center"
    }
});

export default VideoPlayer;