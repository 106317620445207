import React from "react";
import {StyleSheet, View, ViewStyle} from 'react-native';
import Text from "./Text";

type ListProps = {
    items: string[];
    style?: ViewStyle;
};

const List: React.VFC<ListProps> = ({ items, style }) =>
    <View style={styles.list}>
        {items.map((t, i) => <View style={styles.item} key={i}>
            <Text style={styles.bullet}>&#8226;</Text>
            <Text style={styles.text}>{t}</Text>
        </View>)}
    </View>;

const styles = StyleSheet.create({
    list: {
        paddingHorizontal: 20
    },
    item: {
        flexDirection: "row",
        alignItems: "flex-start"
    },
    text: {
        color: "#000",
        textAlign: "left",
        fontSize: 14,
        lineHeight: 23,
    },
    bullet: {
        color: "#000",
        marginTop: -3,
        marginRight: 10
    },
})

export default List;