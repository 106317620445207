import {RootStackParamList} from "../types";
import React from "react";
import {Image, Pressable, StyleSheet, View} from "react-native";
import Text from "./common/Text";
import Button from "./common/Button";
import ImageAssets from "../assets/images/ImageAssets";
import {useConclusion, useStory} from "../hooks/useStory";
import Emoji from "./common/Emoji";
import {NavigationProp, useNavigation} from "@react-navigation/native";
import useDialogSize from "../hooks/useDialogSize";

type ConclusionProps = {
    story: number;
    conclusion: number;
    onRedo: () => void;
    onClose: () => void;
};

const Conclusion: React.VFC<ConclusionProps> = ({ story,conclusion, onRedo, onClose }) => {
    const { color } = useStory(story);
    const { emoji, title } = useConclusion(story, conclusion);

    const { reset } = useNavigation<NavigationProp<RootStackParamList>>();
    const _home = () => {
        reset({
            index: 0,
            routes: [{ name: "Home" }]
        });
    };

    const size = useDialogSize(0.55);

    return (
        <View style={[ styles.modal, size]}>
            <View style={styles.close}>
                <Pressable onPress={onClose}>
                    <Image source={ImageAssets.Close} />
                </Pressable>
            </View>

            <View style={styles.main}>
                <Emoji char={emoji} size={70} />
                <Text style={styles.title}>{title}</Text>
                <Text style={styles.sub}>Do you want to:</Text>
            </View>

            <View style={styles.button}>
                <Button label="Redo Story" onPress={onRedo} type="minor" background={color} border={color} />
                <Button label="Return Home" onPress={_home} type="minor" background="#fff" color="#000" />
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    modal: {
        backgroundColor: "#ffffff",
        borderRadius: 24,
        padding: 30,
        justifyContent: "center"
    },
    close: {
        flexDirection: "row",
        justifyContent: "flex-end"
    },
    main: {
        flexGrow: 1,
        justifyContent: "flex-end",
        alignItems: "center"
    },
    title: {
        fontFamily: "Urbanist_Bold",
        fontSize: 20,
        marginTop: 20,
        marginBottom: 15,
        color: "#000"
    },
    sub: {
        color: "#000",
        fontSize: 14,
        marginBottom: 20
    },
    button: {
        flexGrow: 1,
        justifyContent: "flex-start",
        paddingHorizontal: 40
    }
});

export default Conclusion;