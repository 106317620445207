import {Conclusion, Story, Video} from "../types";
import React, {useContext} from "react";
import combo from "../components/Combo";
import question from "../components/Question";


export const StoriesContext = React.createContext<Story[]>([]);

export function useVideo(story: number, choices: number[]): Video {
    let video = useStory(story).video;

    for (const c of choices) {
        /* Direct link to video. */
        if (video.video) {
            video = video.video;
        }
        else if (video.question) {
            if (!video.question.video) {
                console.error("Question dead end", question, choices)
                throw new Error("Question has no navigable path.");
            }

            video = video.question.video;
        }
        else if (video.combo) {
            if (video.combo.video)
                video = video.combo.video;
            else if (video.combo.choice) {
                if (c >= video.combo.choice.options.length)
                    throw new Error(`Combo choice ${c} out of range for video`);

                video = video.combo.choice.options[c].video;
            }
            else {
                console.error("Combo dead end", combo, choices)
                throw new Error("Combo has no navigable path.");
            }
        }
        else if (video.choice) {
            if (c >= video.choice.options.length)
                throw new Error(`Choice ${c} out of range for video`);

            video = video.choice.options[c].video;
        }
        else {
            console.error("Video dead end", video, choices)
            throw new Error("Video has no navigable path.");
        }
    }

    return video;
}

export function useConclusion(story: number, conclusion: number): Conclusion {
    const conclusions = useStory(story).conclusions;

    if (conclusion < conclusions.length)
        return conclusions[conclusion];
    else
        throw new Error(`Conclusion ${conclusion} not in story`);
}

export function useStory(story: number): Story {
    const stories = useStories();

    if (story < stories.length)
        return stories[story];
    else {
        console.error(`Invalid story index ${story}, number of stories ${stories.length}`);
        throw new Error(`Story ${story} does not exist.`);
    }
}

export function useStories(): Story[] {
    return useContext<Story[]>(StoriesContext);
}

