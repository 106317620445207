import React from "react";
import {Image, StyleSheet, View, ViewStyle} from "react-native";
import {EmojiIcon} from "../../types";
import EmojiAssets from "../../assets/images/emojis/EmojiAssets";

type EmojiProps = {
    char: EmojiIcon;
    style?: ViewStyle;
    size?: number
}

const Emoji: React.VFC<EmojiProps> = ({ char, size = 60, style }) =>
    <View style={[styles.container, style]}>
       <Image source={EmojiAssets[char]} style={[ styles.image, { height: size, width: size } ]} />
    </View>;

const styles = StyleSheet.create({
    container: {
        height: 60,
        justifyContent: "center"
    },
    image: {
        resizeMode: "contain"
    }
});

export default Emoji;