import ScreenView from "../components/common/ScreenView";
import React, {useEffect, useRef, useState} from "react";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {RootStackParamList} from "../types";
import {useStory, useVideo} from "../hooks/useStory";
import {StyleSheet, View} from "react-native";
import VideoPlayer, {VideoRef} from "../components/VideoPlayer";
import Choice from "../components/Choice";
import {NavigationProp, useNavigation} from "@react-navigation/native";
import {useBackHandler} from "@react-native-community/hooks";
import Question from "../components/Question";
import Conclusion from "../components/Conclusion";
import Combo from "../components/Combo";
import Expert from '../components/Expert';
import Button from '../components/common/Button';
import Disclaimer from '../components/Disclaimer';

/**
 * Displays a story.
 */
const StoryScreen: React.VFC<NativeStackScreenProps<RootStackParamList, "Story">> = ({ route }) => {
    const { story, choices } = route.params;

    const { color } = useStory(story);
    const video = useVideo(story, choices);

    const [ disclaimer, setDisclaimer ] = useState(false);
    const [ playCount, setPlayCount ] = useState(0);
    const [ finished, setFinished ] = useState(false);

    useEffect(() => {
        setPlayCount(0);
        setFinished(false);
    }, [ choices ])

    const ref = useRef<VideoRef>(null);
    const _replay = () => {
        ref.current?.replay();
        setFinished(false);
    };

    useEffect(() => {
        /* Hack for iOS sometimes not loading videos. */
        setFinished(false);
        if (!video.expert)
            _replay();
    }, [ video.uri ]);

    const { navigate, getState, addListener } = useNavigation<NavigationProp<RootStackParamList>>();
    useEffect(() => {
        const focusUnsub = addListener("focus", _replay);
        const blurUnsub = addListener("blur", () => ref.current?.stop());

        return () => {
            focusUnsub();
            blurUnsub();
        }
    }, []);

    useBackHandler(() => {
        const state = getState();
        const current = state.routes[state.routes.length - 1];
        if (current.name === "Story" && choices.length > 0) {
            navigate("Story", { story, choices: choices.slice(0, choices.length - 1) });
            return true;
        }
        else
            return false;
    });

    const _goto = (choices: number[]) => {
        setFinished(false);
        navigate("Story", { story, choices });
    };

    const _choice = (choice: number) =>  _goto([...choices, choice]);
    const _next = () => _goto([...choices, 0]);
    const _redo = () => _goto([]);

    const _finished = () => {
        setFinished(true);
        setPlayCount(playCount + 1);

        if (video.video !== undefined) {
            _next();
        }
    };

    const _skip = async () => {
        await ref.current?.pause();
        setFinished(true);
    };

    return (
        <ScreenView style={styles.background} header headerColor="white" headerMask={finished}>
            {!disclaimer && <Disclaimer onClose={() => setDisclaimer(true)} />}

            {disclaimer && <VideoPlayer
                uri={video.uri}
                placeholder={video.placeholder}
                overlay={video.expert ? <Expert background={color} /> : undefined}
                onFinish={_finished}
                ref={ref}
            />}

             {playCount > 0 && !finished && <View style={styles.skip}>
                <View style={{ width: 220 }}>
                    <Button label="Skip" onPress={_skip} type="secondary"/>
                </View>
            </View>}

            { finished && <View style={styles.modal}>
                {video.choice && <Choice choice={video.choice} color={color}
                                         onChoice={_choice} onClose={_replay}/>}
                {video.question && <Question question={video.question} color={color}
                                             onNext={_next} onClose={_replay}/>}
                {video.combo && <Combo combo={video.combo} color={color}
                                       onChoice={_choice} onReplay={_replay} />}
                {video.conclusion !== undefined && <Conclusion story={story} conclusion={video.conclusion}
                                                               onRedo={_redo} onClose={_replay} />}
            </View>}
        </ScreenView>
    )
};

const styles = StyleSheet.create({
    background: {
        backgroundColor: "#000000",
        justifyContent: "center"
    },
    modal: {
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.43)",
        alignItems: "center",
        justifyContent: "center"
    },
    skip: {
        position: "absolute",
        left: 0,
        bottom: 50,
        width: '100%',
        alignItems: "center",
    }
});

export default StoryScreen;