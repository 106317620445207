import * as React from 'react';

import {DefaultTheme, NavigationContainer} from '@react-navigation/native';
import {createNativeStackNavigator} from '@react-navigation/native-stack';

import LinkingConfiguration from './linking';

import HomeScreen from "../screens/Home";
import TitleScreen from "../screens/Title";
import StoryScreen from "../screens/Story";
import ConclusionScreen from "../screens/Conclusion";
import {View} from "react-native";
import ContentScreen from "../screens/Content";
import BioScreen from "../screens/Bio";

const Navigation: React.VFC = () => {
    return (
        <NavigationContainer
            linking={LinkingConfiguration}
            theme={DefaultTheme} >
            <RootNavigator/>
        </NavigationContainer>
    );
};

export default Navigation;
const Stack = createNativeStackNavigator<any>();

const RootNavigator: React.VFC = () => {
    return (
        <Stack.Navigator screenOptions={{
            header: () => <View />
        }}>
            <Stack.Screen name="Title" component={TitleScreen} />
            <Stack.Screen name="Home" component={HomeScreen} />
            <Stack.Screen name="Story" component={StoryScreen} />
            <Stack.Screen name="Conclusion" component={ConclusionScreen} />
            <Stack.Screen name="About">
                {() => <ContentScreen page="about" />}
            </Stack.Screen>
            <Stack.Screen name="Bio" component={BioScreen} />
            <Stack.Screen name="Resources">
                {() => <ContentScreen page="resources" />}
            </Stack.Screen>
            <Stack.Screen name="TermsConditions">
                {() => <ContentScreen page="terms" />}
            </Stack.Screen>
        </Stack.Navigator>
    );
};
