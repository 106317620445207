import React, {useState} from "react";
import {Image, StyleSheet, View} from "react-native";
import ImageAssets from "../../assets/images/ImageAssets";
import {useInterval} from "../../hooks/useInterval";

const XOff = [
    [-18, -42, -65, -88, -114 ],
    [-18, -42, -65, -89 ]
];
const YOff = [ -18, -50 ];

const Spinner: React.VFC = () => {
    let [frame, setFrame] = useState(1);
    useInterval(() => setFrame(Math.max(1, (frame + 1) % 9)), 150);

    const col = frame % 5;
    const row = Math.floor(frame / 5);

    return (
        <View style={styles.clip}>
            <Image source={ImageAssets.Loading} style={[
                styles.image,
                {
                    left: XOff[row][col],
                    top: YOff[row]
                }
            ]} />
        </View>
    )
};

const styles = StyleSheet.create({
   clip: {
       position: "absolute",
       left: "50%",
       top: "50%",
       width: 25,
       height: 25,
       overflow: "hidden",
       transform: [{ scale: 2 }]
   },
   image: {
       resizeMode: "cover",
   }
});

export default Spinner;