import React from "react";
import {View, StyleSheet} from 'react-native';
import Header, {HeaderProps} from "../Header";

export type ViewProps = View['props'] & {
    header?: boolean;
    headerColor?: HeaderProps["color"];
    headerMask?: boolean;
};

const ScreenView: React.FC<ViewProps> = ({ header = false, headerColor = "black",
                                             headerMask = false, style, children, ...otherProps}) =>
    <View style={[styles.screen, style, {overflow: 'hidden'}]} {...otherProps}>
        {children}
        {header && <Header color={headerColor} mask={headerMask}/>}
    </View>;

const styles = StyleSheet.create({
    screen: {
        backgroundColor: "#f2f2f2",
        height: "100%",
        display: "flex"
    }
});

export default ScreenView;