import React from 'react';
import ScreenView from './common/ScreenView';
import Emoji from './common/Emoji';
import Heading from './common/Heading';
import {Image, Pressable, StyleSheet} from 'react-native';
import ImageAssets from '../assets/images/ImageAssets';

type ExpertProps = {
    background: string;
    onClose?: () => void;
}

const Expert: React.VFC<ExpertProps> = ({ background, onClose }) => (

    <ScreenView style={[styles.overlay, { backgroundColor: background }]}>
        <Emoji char="Doctor" />
        <Heading style={styles.heading}>Let's hear what the experts have to say</Heading>
        <Pressable style={styles.button} onPress={onClose}>
             <Image source={ImageAssets.Back} style={styles.image} />
        </Pressable>
    </ScreenView>
);

const styles = StyleSheet.create({
    overlay: {
        position: "absolute",
        left: 0,
        top: 0,
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        padding: 50
    },
    heading: {
        marginVertical: 35
    },
    button: {
        marginTop: 50,
        width: 63,
        height: 63,
        borderRadius: 63,
        backgroundColor: "#000000",
        alignItems: "center",
        justifyContent: "center"
    },
    image: {
        width: 31,
        height: 27
    }
});

export default Expert;