import React from "react";
import {Image, ScrollView, StyleSheet, View} from "react-native";

import ScreenView from "../components/common/ScreenView";

import Content from "../assets/content";
import Images from "../assets/content/images";
import Markdown, {ASTNode} from "react-native-markdown-display";
import EmojiAssets from "../assets/images/emojis/EmojiAssets";

const ContentScreen: React.VFC<{
    page: keyof typeof Content,
    footer?: keyof typeof Content,
    overrides?: StyleSheet.NamedStyles<any>
}> = ({ page, footer, overrides = {} }) => {
    const mergedStyles: { [key: string]: any } = { ...markdownStyles };
    for (const item in overrides) {
        if (item in mergedStyles)
            mergedStyles[item] = { ...mergedStyles[item], ...overrides[item] };
        else
            mergedStyles[item] = overrides[item]
    }

    const rules = {
        image: (node: ASTNode) => {
            let { src } = node.attributes;

            let prefix = "";
            const pos = src.lastIndexOf("/");
            if (pos >= 0) {
                prefix = src.substring(0, pos);
                src = src.substring(pos + 1);
            }

            const [ key, ...add ] = src.split(":");
            const addStyles = add.map((s: string) => mergedStyles[s]);

            let asset = null;
            if (prefix === "emojis" && key in EmojiAssets) {
                asset = EmojiAssets[key];
                addStyles.unshift(mergedStyles.emojis);
            } else if (key in Images)
                asset = Images[key];

            if (asset)
                return <Image key={node.key} source={asset} style={[ mergedStyles.image, ...addStyles ]}/>;
            else {
                console.error(`Image asset from ${src} not found.`);
                return null;
            }
        }
    };

    return (
        <ScreenView header style={styles.background}>
            <ScrollView>
                <Markdown style={mergedStyles} rules={rules}>{Content[page]}</Markdown>
            </ScrollView>
            { footer && <View style={styles.footer}>
                <Markdown style={mergedStyles} rules={rules}>{Content[footer]}</Markdown>
            </View>}
        </ScreenView>
    )
};

const styles = StyleSheet.create({
    background: {
        width: "100%",
        height: "100%",
        backgroundColor: "#ffffff",
        paddingHorizontal: 52,
        paddingTop: 90
    },
    footer: {
        flexGrow: 1,
        justifyContent: "flex-end",
        paddingBottom: 20
    }
});

const markdownStyles = StyleSheet.create({
    heading1: {
        fontFamily: "Urbanist_Bold",
        fontSize: 20,
        lineHeight: 36
    },
    heading2: {
        fontFamily: "Urbanist_Bold",
        fontSize: 16,
        marginTop: 18,
        marginBottom: 10
    },
    link: {
        color: "#33e6ff",
        fontFamily: "Urbanist_Regular",
        fontSize: 14,
    },
    paragraph: {
        marginBottom: 10,
        fontFamily: "Urbanist_Medium",
        fontSize: 14,
        lineHeight: 25,
        alignItems: "center"
    },
    bullet_list: {
        fontFamily: "Urbanist_Medium",
        fontSize: 14,
        lineHeight: 25
    },
    list_item: {
        marginBottom: 10
    },
    emojis: {
        width: 18,
        height: 18,
        resizeMode: "contain",
    },
    hr: {
        backgroundColor: "transparent",
        marginVertical: 30
    }
});

export default ContentScreen;
