import {Size} from "../types";
import {useWindowDimensions} from "react-native";

function useDialogSize(percent?: number): Size {
    const { width, height } = useWindowDimensions();

    if (!percent)
        percent = height / width > 2 ? 0.7 : 0.8;

    return {
        width: Math.min(width - 60, 600),
        height: Math.min(height * percent, 800)
    };
}

export default useDialogSize;