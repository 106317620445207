import React, {useEffect, useMemo, useRef} from "react";
import {Animated, Easing, useWindowDimensions} from "react-native";
import {StyleSheet, Text} from "react-native";

const EmojiChars =
    "🙂😊😀😁😃😄😍😆😂️🙁😠😡😞😟😣😖😢😭🥲😂😨😧😦😱😫😩😮😯" +
    "😲😗😙😚😘😍😉😜😘😛😝😜🤑🤔😕😟😐😑😳😞😖🤐😶😇👼😎😪😏" +
    "😒😵😵‍😕🤕🤒😷🤢🤨😬";


/**
 * Emojis characters.
 */
const Emojis: React.VFC<{ col: number }> = ({ col}) => {
    const chars = useMemo(() => {
        const c = [ ...EmojiChars ].sort(() => Math.random() - 0.5);
        c.push(...c);
        return c;
    }, []);

    const { height } = useWindowDimensions();
    const len = chars.length * 22;

    const y = useRef(new Animated.Value(col % 2 ? height - len : 0)).current;
    const animation = useRef(Animated.timing(y, {
        useNativeDriver: true,
        duration: 60000,
        toValue: col % 2 ? 0 : height - len,
        easing: Easing.linear
    }));

    useEffect(() => {
        Animated.loop(animation.current).start();
    }, [animation]);

    return (
        <Animated.View style={[ styles.column, { left: col * 40, transform: [{ translateY: y }] } ]}>
            <Text style={styles.text}>{chars}</Text>
        </Animated.View>
    )
};

const styles = StyleSheet.create({
    column: {
        position: "absolute",
        width: 35,
    },
    text: {
        fontSize: 27,
        lineHeight: 45,
    }
})

export default Emojis;