import React from "react";
import {Story} from "../../types";
import {Image, StyleSheet, View} from "react-native";

type CardProps = {
    story: Story;
    selected: boolean;
};

const Card: React.VFC<CardProps> = ({ story, selected}) => {
    const { background, video } = story;

    return (
        <View style={[styles.card, {
            borderColor: (selected ? "#000" : background)
        }]}>
            <Image source={{ uri: video.placeholder}} style={styles.image} />
        </View>
    )
};

const styles = StyleSheet.create({
    card: {
        backgroundColor: "#000",
        borderStyle: "solid",
        borderWidth: 3,
        borderRadius: 24,
        height: "100%",
        overflow: "hidden"
    },
    image: {
        width: "100%",
        height: "100%",
    }
});

export default Card;