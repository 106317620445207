import React from "react";
import {View} from "react-native";

type RectProps = {
    width: number | string;
    height: number | string;
};

export const Rect: React.VFC<RectProps> = ({ width, height }) =>
    <View style={[styles.fill, { width, height }]}></View>;

const styles = {
    fill: {
        backgroundColor: "#f0f0f0"
    }
};

export const Horizontal = (props: Partial<RectProps>) => <Rect width="100%" height={1} {...props} />;
export const Vertical = (props: Partial<RectProps>) => <Rect width={1} height="100%" {...props} />;