import {Question as QuestionType} from "../types";
import React, {useEffect, useState} from "react";
import {Image, Pressable, ScrollView, StyleSheet, View} from "react-native";
import Text from "./common/Text";
import {Horizontal} from "./common/Line";
import Button from "./common/Button";
import ImageAssets from "../assets/images/ImageAssets";
import useDialogSize from "../hooks/useDialogSize";
import Radio from './common/Radio';

type QuestionProps = {
    question: QuestionType;
    color: string;
    onNext: () => void;
    onClose: () => void;
};

const Question: React.VFC<QuestionProps> = ({ question, color, onNext, onClose }) => {
    const { title, description, options, reason, checkbox } = question;

    const [ selected, setSelected ] = useState<number[]>([]);
    const [ locked, setLocked ] = useState(false);

    const _set = (option: number) => {
        if (!locked) {
            if (checkbox) {
                const pos = selected.indexOf(option);
                if (pos < 0)
                    setSelected([ ...selected, option ]);
                else {
                    const update = [ ...selected ];
                    update.splice(pos, 1);
                    setSelected(update);
                }
            }
            else
                setSelected([ option ]);
        }
    }

    const _tryAgain = () => {
        setSelected([]);
        setLocked(false);
    }

    useEffect(() => {
        _tryAgain();
    }, [question]);

    const size = useDialogSize();

    return (
        <View style={[ styles.modal, size ]}>
            <View style={styles.close}>
                <Pressable onPress={onClose}>
                    <Image source={ImageAssets.Close} />
                </Pressable>
            </View>

            <View style={styles.description}>
                <Text style={[styles.text, styles.title]}>{title}</Text>
                <Text style={styles.text}>{description}</Text>
                {checkbox && <Text style={styles.smallText}>Select all that apply</Text>}
            </View>

            <ScrollView style={styles.options}>
                <Horizontal />
                {options.map((opt, i) => {
                    const optSelected = selected.indexOf(i) >= 0;
                    const optLocked = locked && optSelected;

                    let optColor;
                    if (locked)
                        optColor = opt.correct ? "#0FCD71" : "#EC4646";
                    else if (optSelected)
                        optColor = color;
                    else
                        optColor = "#000000";

                    return (
                        <React.Fragment key={i}>
                            <Pressable style={styles.item} onPress={() => _set(i)}>
                                <View style={[
                                    styles.itemContent,
                                    optLocked ? styles.itemContentLocked : styles.itemContentNot,
                                ]}>
                                    <Text style={[
                                        styles.text,
                                        styles.itemText,
                                        optSelected ? styles.itemSelected : styles.itemNot,
                                        {
                                            color: optColor
                                        }]}>{opt.label}</Text>
                                    {locked &&<Text style={[styles.reason, { color: optColor }]}>{opt.reason}</Text>}
                                </View>
                                <Radio color={optColor} selected={optSelected} checkbox={checkbox}
                                       borderColor={locked ? optColor: undefined} />
                            </Pressable>

                            <Horizontal />
                        </React.Fragment>
                    );
                })}
                {locked && reason && <Text style={[ styles.reason, styles.commonReason ]}>{reason}</Text>}
            </ScrollView>

            <View style={styles.button}>
                {!locked && <>
                    <Button label="Check Answer" onPress={() => setLocked(true)} type="minor" disabled={selected.length === 0} />
                    <Button label="Replay Video" onPress={onClose} type="minor" background="#fff" color="#000" />
                </>}

                {locked && <>
                    <Button label="Next" onPress={onNext} type="minor" disabled={selected.length === 0} />
                </>}
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    modal: {
        backgroundColor: "#ffffff",
        borderRadius: 24,
        padding: 25,
        justifyContent: "center"
    },
    close: {
        flexDirection: "row",
        justifyContent: "flex-end"
    },
    title: {
        fontFamily: "Urbanist_Bold",
        fontSize: 20,
        marginBottom: 15
    },
    text: {
        textAlign: "left",
        color: "#000000",
        fontSize: 14,
        lineHeight: 25
    },
    smallText: {
        textAlign: "left",
        color: "#000000",
        fontSize: 10,
        lineHeight: 18,
        fontStyle: "italic"
    },
    description: {
        marginTop: 30,
        justifyContent: "flex-end"
    },
    options: {
        marginTop: 20,
        flexGrow: 1
    },
    item: {
        flexDirection: "row",
        alignItems: "center",
        width: "100%"
    },
    itemContent: {
        flexGrow: 1,
        flexShrink: 1,
        paddingRight: 20,
        justifyContent: "center"
    },
    itemContentLocked: {
        paddingVertical: 5,
    },
    itemContentNot: {
        paddingVertical: 20,
    },
    itemText: {
        textAlign: "left",
        fontFamily: "Urbanist_Regular",
    },
    itemSelected: {
        fontFamily: "Urbanist_Bold",
    },
    itemNot: {

    },
    reason: {
        fontFamily: "Urbanist_SemiBold",
        fontSize: 11,
        lineHeight: 18,
        textAlign: "left",
        fontStyle: "italic",
    },
    commonReason: {
        color: "#0FCD71",
        marginTop: 10
    },
    button: {
        marginTop: 20,
        marginBottom: 20,
        flexGrow: 1,
        justifyContent: "flex-end",
        paddingHorizontal: 30
    }
});

export default Question;