import React from "react";
import {Pressable, StyleSheet} from "react-native";
import Text from "./Text";

type ButtonProps = {
    label: string;
    type?: "primary" | "secondary" | "minor";
    background?: string;
    border?: string;
    color?: string;
    onPress: () => void;
    disabled?: boolean;
};

const Button: React.VFC<ButtonProps> = ({ label, type = "primary",
                                            disabled = false, onPress,
                                            color, background = "#000", border = "#000" }) => {
    const _pressed = () => {
        if (!disabled)
            onPress();
    }

    return (
        <Pressable onPress={_pressed} style={({ pressed }) => [
            styles.button, styles[type],
            {
                backgroundColor: background,
                borderColor: border
            },
            (pressed && !disabled ? styles.pressed : styles.not),
            (disabled ? styles.disabled : styles.not)
        ]}>
            <Text style={[
                styles.text,
                { color: disabled ? "#E9E9E9" : (color ?? "#fff") },
                { fontSize: type === "primary" ? 25 : 19 }
            ]}>{label}</Text>
        </Pressable>
    );
};

const styles = StyleSheet.create({
    button: {
        width: "100%",
        borderRadius: 40,
        borderColor: "#000000",
        borderWidth: 3,
        borderStyle: "solid"
    },
    primary: {
        marginVertical: 30,
        paddingVertical: 15,
        backgroundColor: "#33e6ff"
    },
    secondary: {
        marginVertical: 30,
        backgroundColor: "#000",
        paddingVertical: 8
    },
    minor: {
        marginVertical: 5,
        paddingVertical: 8
    },
    pressed: {
        opacity: 0.75
    },
    not: {

    },
    text: {
        textAlign: "center",
        fontFamily: "Urbanist_SemiBold"
    },
    disabled: {
        backgroundColor: "#b6b6b6",
        borderColor: "#A9A9A9",
        borderWidth: 2
    }
});

export default Button;