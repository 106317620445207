import {Choice as ChoiceType} from "../types";
import React, {useState} from "react";
import {Image, Pressable, StyleSheet, View} from "react-native";
import Text from "./common/Text";
import {Horizontal} from "./common/Line";
import Button from "./common/Button";
import ImageAssets from "../assets/images/ImageAssets";
import useDialogSize from "../hooks/useDialogSize";
import Radio from './common/Radio';

type ChoiceProps = {
    choice: ChoiceType;
    color: string;
    onChoice: (choice: number) => void;
    onClose: () => void;
};

const Choice: React.VFC<ChoiceProps> = ({ choice, color, onChoice, onClose }) => {
    const { title, description, options } = choice;

    const [ selected, set ] = useState<number | null>(null);

    const _lockIn = () => {
        if (selected !== null)
            onChoice(selected);
    };

    const size = useDialogSize(0.65);

    return (
        <View style={[ styles.modal, size ]}>
            <View style={styles.close}>
                <Pressable onPress={onClose}>
                    <Image source={ImageAssets.Close} />
                </Pressable>
            </View>

            <View style={styles.description}>
                <Text style={[styles.text, styles.title]}>{title}</Text>
                <Text style={styles.text}>{description}</Text>
            </View>

            <View style={styles.options}>
                <Horizontal />
                {options.map((opt, i) => <React.Fragment key={i}>
                    <Pressable style={styles.item} onPress={() => set(i)}>
                        <Text style={[
                            styles.text,
                            styles.itemText,
                            i === selected ? styles.itemSelected : styles.itemNot,
                            { color: (i === selected ? color : "#000000") }
                        ]}>{opt.label}</Text>
                        <Radio selected={i === selected} color={color} />
                    </Pressable>
                    <Horizontal />
                </React.Fragment>)}
            </View>

            <View style={styles.button}>
                <Button label="Lock It In" onPress={_lockIn} type="minor"
                        disabled={selected === null} />
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    modal: {
        backgroundColor: "#ffffff",
        borderRadius: 24,
        padding: 30,
        justifyContent: "center"
    },
    close: {
        flexDirection: "row",
        justifyContent: "flex-end"
    },
    title: {
        fontFamily: "Urbanist_Bold",
        fontSize: 20,
        marginBottom: 15
    },
    text: {
        textAlign: "left",
        color: "#000000",
        fontSize: 14,
        lineHeight: 25
    },
    description: {
        flexGrow: 1,
        justifyContent: "flex-end"
    },
    options: {
        marginTop: 20,
        flexGrow: 1,
        width: "100%"
    },
    item: {
        marginVertical: 20,
        flexDirection: "row",
        alignItems: "center",
        position: "relative",
        width: "100%"
    },
    itemText: {
        flexGrow: 1,
        flexShrink: 1,
        paddingRight: 10,
        textAlign: "left",
        fontFamily: "Urbanist_Regular",
    },
    itemSelected: {
        fontFamily: "Urbanist_Bold",
    },
    itemNot: {

    },
    button: {
        paddingHorizontal: 30,
        marginBottom: 30
    }
});

export default Choice;