const EmojiAssets = {
    Bad: require("./bad.png"),
    Chat: require("./chat.png"),
    Doctor: require("./doctor.png"),
    Face: require("./face.png"),
    Family: require("./family.png"),
    Friends: require("./friends.png"),
    Good: require("./good.png"),
    Grandma: require("./grandma.png"),
    Great: require("./great.png"),
    GreenSick: require("./green_sick.png"),
    House: require("./house.png"),
    ManDancing: require("./man_dancing.png"),
    Mum: require("./mum.png"),
    Muscle: require("./muscle.png"),
    One: require("./one.png"),
    Tea: require("./tea.png"),
    Thinking: require("./thinking.png"),
    Three: require("./three.png"),
    ThumbsDown: require("./thumbs_down.png"),
    ThumbsUp: require("./thumbs_up.png"),
    Toilet: require("./toilet.png"),
    Two: require("./two.png"),
    Researcher: require("./researcher.png"),
    Robot: require("./robot.png"),
    School: require("./school.png"),
    WomanDancing: require("./woman_dancing.png"),
    WeightLifter: require("./weightlifter.png")
} as { [key: string]: any };

export default EmojiAssets;