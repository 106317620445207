import {StatusBar} from 'expo-status-bar';
import React, {useEffect, useState} from "react";

import Navigation from "./navigation";
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {useFonts} from "expo-font";
import {getTestStories} from "./api/rest";
import {StoriesContext} from './hooks/useStory';
import {Story} from "./types";
import {Audio} from "expo-av";

const App: React.VFC = () => {
    const [ fontsLoaded ] = useFonts({
        "Urbanist_Regular": require("./assets/fonts/Urbanist/Urbanist-Regular.ttf"),
        "Urbanist_Medium": require("./assets/fonts/Urbanist/Urbanist-Medium.ttf"),
        "Urbanist_SemiBold": require("./assets/fonts/Urbanist/Urbanist-SemiBold.ttf"),
        "Urbanist_Bold": require("./assets/fonts/Urbanist/Urbanist-Bold.ttf"),
        "Urbanist_ExtraBold": require("./assets/fonts/Urbanist/Urbanist-ExtraBold.ttf"),
        "Urbanist_Black": require("./assets/fonts/Urbanist/Urbanist-Black.ttf")
    });

    const [ stories, setStories ] = useState<Story[] | null>(null);
    useEffect(() => {
        getTestStories().then(setStories);

        Audio.setAudioModeAsync({
            playsInSilentModeIOS: true
        })
    }, []);

    if (fontsLoaded && stories !== null) {
        return (
            <SafeAreaProvider>
                <StoriesContext.Provider value={stories || []}>
                    <Navigation/>
                    <StatusBar/>
                </StoriesContext.Provider>
            </SafeAreaProvider>
        );
    }
    else {
        return null;
    }
}

export default App;
