import React from "react";
import {TouchableOpacity} from "react-native";
import Svg, {Text} from "react-native-svg";
import {processFontFamily} from "expo-font";


type FancyLinkProps = {
    label: string,
    onPress: () => void,
    fg: string;
    bg: string;
};

const FancyLink: React.VFC<FancyLinkProps> = ({ label, onPress, fg,bg }) => {

    const width = 200;

    return (
        <TouchableOpacity onPress={onPress}>
            <Svg width={width} height={30}>
                <Text
                    x={5 + 3} y={20 + 3}
                    textAnchor="start"
                    stroke={bg} strokeWidth={5}
                    fill={bg}
                    fontSize={22}
                    strokeLinejoin="round"
                    fontFamily={processFontFamily("Urbanist_ExtraBold") || undefined}
                >{label}</Text>
                <Text
                    x={5} y={20}
                    textAnchor="start"
                    stroke={fg} strokeWidth={4}
                    fill={fg}
                    fontSize={22}
                    fontFamily={processFontFamily("Urbanist_ExtraBold") || undefined}
                >{label}</Text>
                <Text
                    x={5} y={20}
                    textAnchor="start"
                    stroke={fg} strokeWidth={0.5}
                    fill="#fff"
                    fontSize={22}
                    fontFamily={processFontFamily("Urbanist_ExtraBold") || undefined}
                >{label}</Text>
            </Svg>
        </TouchableOpacity>
    );
};

export default FancyLink;