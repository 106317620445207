import React, {useRef, useState} from "react";
import {Animated, StyleSheet, useWindowDimensions, View} from 'react-native';
import ScreenView from "../components/common/ScreenView";
import {useStories} from "../hooks/useStory";
import Emoji from "../components/common/Emoji";
import {RootStackParamList} from "../types";
import Heading from "../components/common/Heading";
import Text from "../components/common/Text";
import Button from "../components/common/Button";
import Carousel from "react-native-snap-carousel";
import {NavigationProp, useNavigation} from "@react-navigation/native";
import Card from "../components/home/Card";

/**
 * Home screen which contains a video choosing carousel.
 */
const HomeScreen: React.VFC = () => {
    const stories = useStories();
    const [ selected, setSelected ] = useState(0);

    const story = stories[selected];

    const { width, height } = useWindowDimensions();

    const animation = useRef(new Animated.Value(0)).current;
    const transition = useRef(Animated.timing(animation,{
        useNativeDriver: false,
        toValue: 1,
        duration: 500
    })).current;

    const background = useRef(animation.interpolate({
        inputRange: [0, 1],
        outputRange: [stories[0].background, stories[0].background]
    }));

    const _set = (item: number) => {
        background.current = animation.interpolate({
            inputRange: [0, 1],
            outputRange: [stories[selected].background, stories[item].background]
        });

        transition.reset();
        transition.start();
        setSelected(item);
    };

    const { navigate } = useNavigation<NavigationProp<RootStackParamList>>();
    const _start = () => {
        navigate("Story", { story: selected, choices: [] });
    };

    const carousel = height * 0.5;
    let cardWidth = width - 120;
    if (carousel / cardWidth < 4 / 3)
        cardWidth = carousel * 3 / 4;

    return (
        <ScreenView header headerColor="white">
            <Animated.View style={[ styles.container, { backgroundColor: background.current }]} >
                <View style={styles.emojis}>
                    {story.emoji.map(e => <Emoji key={e} char={e} size={40} />)}
                </View>
                <Heading style={styles.heading}>{story.title}</Heading>
                <Text style={[
                    styles.description,
                    { marginHorizontal: selected === 1 ? 15: 45}
                ]} numberOfLines={2}>{story.description}</Text>

                <View style={{ height: carousel }}>
                    <Carousel
                      data={stories}
                      renderItem={({ item, index }: any) => (
                          <Card story={item} selected={index === selected} />
                      )}
                      sliderWidth={width}
                      itemWidth={cardWidth}
                      onSnapToItem={_set}
                    />
                </View>

                <View style={styles.button}>
                    <Button label="Get Started" type="secondary" onPress={_start} />
                </View>
            </Animated.View>
        </ScreenView>
    );
};

const styles = StyleSheet.create({
    container: {
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 40
    },
    heading: {
        textTransform: "uppercase"
    },
    description: {
        marginHorizontal: 45,
        marginBottom: 30,
        fontSize: 14,
        lineHeight: 20.3
    },
    emojis: {
        flexDirection: "row",
        justifyContent: "center"
    },
    button: {
        width: 220
    },
});

export default HomeScreen;