import React from "react";
import {View, StyleSheet} from 'react-native';
import Text from "./Text";


const Paragraph: React.FC = ({ children }) =>
    <View style={styles.paragraph}>
        <Text style={styles.text}>{children}</Text>
    </View>;

const styles = StyleSheet.create({
    paragraph: {
        marginVertical: 10,
    },
    text: {
        textAlign: "left",
        fontSize: 14,
        lineHeight: 23
    }
});

export default Paragraph;