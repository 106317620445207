import React from 'react';
import ScreenView from './common/ScreenView';
import {ScrollView, StyleSheet, View} from 'react-native';

import Markdown from 'react-native-markdown-display';
import Content from '../assets/content';
import Button from './common/Button';

type DisclaimerProps = {
    onClose: () => void;
}

const Disclaimer: React.VFC<DisclaimerProps> = ({onClose }) => (
    <ScreenView style={[styles.overlay]}>
        <ScrollView>
            <Markdown style={markdown} >{Content.disclaimer}</Markdown>
        </ScrollView>
        <View style={styles.button}>
            <Button label="Start" onPress={onClose} background="#ffffff" color="#000000" type="secondary"/>
        </View>
    </ScreenView>
);

const markdown = StyleSheet.create({
    heading1: {
        fontFamily: "Urbanist_Bold",
        fontSize: 20,
        lineHeight: 36,
        color: '#ffffff',
    },
    paragraph: {
        marginBottom: 10,
        fontFamily: "Urbanist_Medium",
        fontSize: 14,
        lineHeight: 25,
        alignItems: "center",
        color: "#ffffff"
    },
    strong: {
        fontFamily: "Urbanist_Bold",
        color: "#EC4646"
    }
});

const styles = StyleSheet.create({
    overlay: {
        position: "absolute",
        left: 0,
        top: 0,
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        paddingTop: 100,
        paddingHorizontal: 50,
        backgroundColor: '#000000'
    },
    button: {
        width: 220,
        marginTop: 20,
        marginBottom: 50
    }
});

export default Disclaimer;