// @ts-nocheck

import * as about from "./About.md";
import * as about_footer from "./AboutFooter.md";
import * as bio from "./Bios.md";
import * as disclaimer from './Disclaimer.md';
import * as resources from "./Resources.md";
import * as terms from "./TermsConditions.md";

export default {
    about,
    about_footer,
    bio,
    disclaimer,
    resources,
    terms
};
