import {Story} from "../types";

import TestStories from "./stories.json";
import Constants from "../constants";

export async function loadStories(): Promise<Story[]> {
    try {
        const response = await fetch(Constants.Config);
        if (response.ok)
            return await response.json();
        else {
            console.log(`Loading stories config from storage returned code ${response.status}: ${response.statusText}`);
            return [];
        }
    }
    catch (e) {
        console.log("Unable to load stories configuration from storage", e)
        return [];
    }
}

export async function getTestStories(): Promise<Story[]> {
    return TestStories as Story[];
}