import React from 'react';
import {StyleSheet, View, Image} from 'react-native';
import ImageAssets from '../../assets/images/ImageAssets';

type RadioProps = {
    color: string;
    borderColor?: string;
    selected: boolean;
    checkbox?: boolean;
    style?: any
}

const Radio: React.VFC<RadioProps> = ({ color, borderColor, selected, checkbox, style }) => (
    <View style={[
        style,
        styles.input,
        checkbox ? styles.checkbox : styles.radio,
        {
            borderColor: borderColor ?? (selected ? color : "#c9c9c9"),
            backgroundColor: checkbox ? (selected ? color : "#ffffff") : undefined
        }
    ]}>
        <View style={[
            checkbox ? styles.innerCheckbox : styles.innerRadio,
            { backgroundColor: selected ? color : "#fff"}
        ]}>
            {checkbox && selected && <Image source={ImageAssets.Tick} style={styles.image} />}
        </View>
    </View>
);

const styles = StyleSheet.create({
    input: {
        width: 22,
        height: 22,
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 1,
        borderStyle: "solid"
    },
    radio: {
        borderRadius: 23
    },
    checkbox: {
        borderRadius: 3
    },
    innerRadio: {
        width: 15,
        height: 15,
        borderRadius: 15
    },
    innerCheckbox: {
    },
    image: {
        width: 14,
        height: 10
    }
})

export default Radio;