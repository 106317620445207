import React from "react";
import ContentScreen from "./Content";
import {StyleSheet} from "react-native";

const BioScreen: React.VFC = () => (
    <ContentScreen page="bio" overrides={BioStyles}/>
);

const BioStyles = StyleSheet.create({
    heading1: {
        lineHeight: 36
    },
    heading2: {
        marginBottom: 10,
    },
    paragraph: {
        marginBottom: 0,
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        alignItems: "flex-start",
    },
    emojis: {
        marginTop: 2,
        marginRight: 10,
    },
    emj: {
        marginLeft: 15,
        marginRight: 0,
        width: 21,
        height: 20
    },
});

export default BioScreen;