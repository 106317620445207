import React, {useEffect, useRef, useState} from "react";
import {Animated, StyleSheet, useWindowDimensions, View} from "react-native";
import ScreenView from "../components/common/ScreenView";
import TitleText from "../components/title/TitleText";
import Heading from "../components/common/Heading";
import Button from "../components/common/Button";
import Emoji from "../components/common/Emoji";
import {NavigationProp, useNavigation} from "@react-navigation/native";
import {RootStackParamList} from "../types";
import Emojis from "../components/loading/Emojis";

/**
 * Title screen.
 */
const TitleScreen: React.VFC = () => {
    const mRotValue = useRef(new Animated.Value(0)).current;
    const mRot = mRotValue.interpolate({
        inputRange: [0, 1],
        outputRange: [ `${-6 * Math.PI / 180}rad`, "0rad" ]
    });

    const [ title, setTitle ] = useState(true);

    const mTrans = useRef(new Animated.Value(0)).current;
    const aTrans = useRef(new Animated.Value(0)).current;
    const opa = useRef(new Animated.Value(0)).current;

    const { width, height } = useWindowDimensions();

    const animation = useRef(Animated.sequence([
        Animated.timing(mRotValue, {
            useNativeDriver: false,
            toValue: 1,
            delay: 1000,
            duration: 750
        }),
        Animated.timing(mTrans, {
            useNativeDriver: false,
            toValue: height / 2 + 100,
            delay: 1000,
            duration: 1000
        }),
        Animated.timing(aTrans, {
            useNativeDriver: false,
            toValue: height / 2 + 100,
            delay: 100,
            duration: 1000,
        }),
        Animated.timing(opa, {
            useNativeDriver: false,
            toValue: 100,
            duration: 5000
        })
    ])).current;

    useEffect(() => {
        aTrans.addListener(({ value}) => {
            setTitle(value < height / 2 + 100);
        });

        animation.start();
    }, [ animation ]);


    const { reset } = useNavigation<NavigationProp<RootStackParamList>>();
    const _pressed = () => {
        reset({
            index: 0,
            routes: [{ name: "Home" }]
        });
    };

    return (
        <ScreenView style={[ styles.background, { backgroundColor: (title ? "#33E6FF" : "#c270df")}]}>
            {title && <View style={styles.emojis}>
                {new Array(Math.ceil(width / 40)).fill("").map((_, i) => <Emojis col={i} key={i} />)}
            </View>}

            <View style={styles.title}>
                <Animated.View style={{ transform: [ { rotate: mRot }, { translateY: mTrans } ]}}>
                    <TitleText text="MIS" fill="#C270DF" width={80} />
                </Animated.View>

                <Animated.View style={{ transform: [{ translateY: aTrans }] }}>
                    <TitleText text="ADVENTURES" fill="#E5A648" width={250} />
                </Animated.View>
            </View>

            <Animated.View style={[ styles.fadeIn, { opacity: opa }]}>
                <Emoji char="Thinking" size={70} />
                <Heading style={styles.heading}>Can you spot health misinformation online?</Heading>

                <View style={styles.button}>
                    <Button label="Test yourself" type="primary" background="#33E6FF" color="#000" onPress={_pressed} />
                </View>
            </Animated.View>
        </ScreenView>
    );
};

const styles = StyleSheet.create({
    background: {
        justifyContent: "center",
        alignItems: "center"
    },
    emojis: {
        position: "absolute",
        left: 0,
        top: 0,
        opacity: 0.5,
    },
    title: {
        flexDirection: "row"
    },
    fadeIn: {
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        padding: 35,
        justifyContent: "center",
        alignItems: "center"
    },
    heading: {
        marginTop: 15
    },
    button: {
        width: "100%",
        paddingHorizontal: 10,
        marginTop: -10
    }
});

export default TitleScreen;