import React, {useRef, useState} from "react";
import {Animated, Easing, Image, StyleSheet, TouchableOpacity, useWindowDimensions, View} from "react-native";
import ImageAssets from "../assets/images/ImageAssets";
import {NavigationProp, useNavigation} from "@react-navigation/native";
import {RootStackParamList} from "../types";
import FancyLink from "./common/FancyLink";

const AnimationParams = {
    useNativeDriver: true,
    duration: 400,
    easing: Easing.exp
};

export type HeaderProps = {
    color?: "white" | "black";
    mask?: boolean;
};

/**
 * Header with hamburger.
 */
const Header: React.VFC<HeaderProps> = ({ color, mask }) => {
    const { width, height } = useWindowDimensions();
    const panelWidth = Math.min(width * 0.8, 300);

    const [ open, setOpen ] = useState(false);

    const left = useRef(new Animated.Value(-panelWidth - 10)).current;
    const opening = useRef(Animated.timing(left, {
        ...AnimationParams,
        toValue: 0.
    }));

    const closing = useRef(Animated.timing(left, {
        ...AnimationParams,
        toValue: -panelWidth -10
    }));

    const _open = () => {
        opening.current.reset();
        opening.current.start(() => setOpen(true));
    };

    const _close = () => {
        closing.current.reset();
        closing.current.start(() => setOpen(false));
    }

    const { reset, navigate } = useNavigation<NavigationProp<RootStackParamList>>();

    const links = [
        { label: "Home", fg: "#c270df", bg: "#e5a648", onPress: () => {
            reset({
                index: 0,
                routes: [{ name: "Home" }]
            });
            _close();
        }},
        { label: "Bios", fg: "#33e6ff", bg: "#c270df", onPress: () => {
                navigate("Bio");
                _close();
            }
        },
        { label: "Resources", fg: "#ee6295", bg: "#87ef83", onPress: () => {
                navigate("Resources");
                _close();
            }
        },
        { label: "About", fg: "#f19a49", bg: "#33e6ff", onPress: () => {
            navigate("About");
            _close();
        }},
        { label: "Ts&Cs", fg: "#33e6ff", bg: "#87ef63", onPress: () => {
            navigate("TermsConditions");
            _close();
        }}
    ];

    return (
        <React.Fragment>
            <View style={[styles.header, (mask ? styles.mask : styles.notMask)]}>
                <TouchableOpacity onPress={_open} disabled={mask}>
                    <Image
                        source={color === "white" ? ImageAssets.HamburgerWhite : ImageAssets.Hamburger}
                        style={styles.image}
                    />
                </TouchableOpacity>
            </View>

            <Animated.View style={[styles.draw, {
                height: height + 65,
                width: panelWidth,
                transform: [{ translateX: left }]
            }]}>
                <TouchableOpacity onPress={_close} style={styles.close}>
                    <Image source={ImageAssets.Close} />
                </TouchableOpacity>

                <View style={styles.links}>
                    {links.map(link => <View key={link.label} style={styles.link}>
                        <FancyLink {...link} />
                    </View>)}
                </View>
            </Animated.View>
        </React.Fragment>
    );
};

const styles = StyleSheet.create({
    header: {
        position: "absolute",
        left: 20,
        top: 46,
        width: 30,
        height: 20,
        justifyContent: "center",
        alignItems: "center"
    },
    image: {
        width: 25,
        height: 20
    },
    mask: {
        opacity: 0.5
    },
    notMask: {

    },
    draw: {
        position: "absolute",
        left: 0,
        top: 0,
        backgroundColor: "#fff",
        borderTopRightRadius: 24,
        borderBottomRightRadius: 24,
        shadowColor: "#000000",
        shadowOffset: {
            width: 3,
            height: 0,
        },
        shadowOpacity: 0.5,
        shadowRadius: 4.65,
        elevation: 10,
    },
    close: {
        position: "absolute",
        right: 26,
        top: 50
    },
    links: {
        marginHorizontal: 40,
        marginTop: 120
    },
    link: {
        height: 80,
        justifyContent: "space-evenly",
        borderBottomWidth: 1,
        borderBottomColor: "#f0f0f0"
    }
})

export default Header;