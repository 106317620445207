import React, {useState} from "react";
import {NativeStackScreenProps} from "@react-navigation/native-stack";

import {RootStackParamList, RootStackScreenProps} from "../types";
import ScreenView from "../components/common/ScreenView";
import {useConclusion} from "../hooks/useStory";
import Heading from "../components/common/Heading";
import {Image, ScrollView, StyleSheet, useWindowDimensions, View} from "react-native";
import Carousel from "react-native-snap-carousel";
import Emoji from "../components/common/Emoji";
import {Horizontal} from "../components/common/Line";
import Button from "../components/common/Button";
import {NavigationProp, useNavigation} from "@react-navigation/native";
import List from "../components/common/List";
import Paragraph from "../components/common/Paragraph";

/**
 * Displays the conclusion to a story.
 */
const ConclusionScreen: React.VFC<RootStackScreenProps<"Conclusion">> = ({ route }) => {
    const { story, conclusion } = route.params;
    const { emoji, title } = useConclusion(story, conclusion);

    const content: string[] = [];
    const image = "";

    const { width, height } = useWindowDimensions();

    const screens = [ 0, 1 ];
    const [ screen, setScreen ] = useState(0);

    const { reset } = useNavigation<NavigationProp<RootStackParamList>>();
    const _home = () => {
        reset({
            index: 0,
            routes: [{ name: "Home" }]
        });
    };

    const _item = ({ index }: any) =>
        <View style={styles.item}>
            {index === 0 && <Emoji char={emoji} style={styles.emoji} />}
            <Heading style={styles.heading}>{title}</Heading>
            {index === 0 && <Image style={styles.image} source={{ uri: image }} />}

            {index === 1 && <View style={styles.main}>
                <ScrollView>
                    {content.map((c, i) => <React.Fragment key={i}>
                        {Array.isArray(c) ? <List items={c} /> : <Paragraph>{c}</Paragraph>}
                    </React.Fragment>)}
                </ScrollView>

                <View style={styles.bottom}>
                    <Horizontal height={2} />
                    <View style={styles.button}>
                        <Button label="Try Again" type="secondary" background="#e5a648" onPress={_home} />
                    </View>
                </View>
            </View>}
        </View>;

    return (
        <ScreenView style={styles.background} header>

            <View style={[ styles.tabs, { height: height - 120 }]}>
                <Carousel
                    data={screens}
                    renderItem={_item}
                    sliderWidth={width}
                    itemWidth={width - 40}
                    onSnapToItem={setScreen}
                />
            </View>

            <View style={styles.indicators}>
                {screens.map(s => <View key={s} style={[styles.indicator, (s === screen ? styles.current : styles.not)]}/>)}
            </View>
        </ScreenView>
    )
};

const styles = StyleSheet.create({
    background: {
        backgroundColor: "#ee6295",
        alignItems: "center"
    },
    tabs: {
        marginTop: 60
    },
    item: {
        width: "100%",
        height: "100%",
        padding: 15,
        alignItems: "center",
    },
    emoji: {
        marginTop: 30
    },
    heading: {
        marginTop: 20,
        marginBottom: 20
    },
    image: {
        width: "100%",
        flexGrow: 1,
        borderWidth: 3,
        borderColor: "#000",
        borderRadius: 24,
    },
    indicators: {
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
        height: 40
    },
    indicator: {
        width: 18,
        height: 18,
        marginHorizontal: 5,
        borderRadius: 14,
        borderWidth: 1.5,
        borderStyle: "solid",
        borderColor: "#000"
    },
    current: {
        backgroundColor: "#e5a648"
    },
    not: {
    },
    main: {
        flexGrow: 1,
    },
    bottom: {
        height: 220,
    },
    button: {
        paddingHorizontal: 40
    }
})

export default ConclusionScreen;